import React from "react";
import { asyncComponent } from "@jaredpalmer/after";

export default [
  {
    path: "/",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Home"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/admin",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Admin"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/post/:id",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Post"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/day/:id",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Day"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: "/login",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Login"),
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
];
