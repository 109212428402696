import React from "react";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ensureReady, After, getSerializedData } from "@jaredpalmer/after";
import "./client.css";
import routes from "./routes";
import Layout from "./Layout";
import { StoreProvider } from "easy-peasy";
import { store } from "./stores/store";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";

const preloadedState = getSerializedData("preloaded_state");
store.dispatch.set_initialState(preloadedState);
delete window._PRELOADED_STATE_;
ensureReady(routes).then((data) =>
  hydrate(
    <StoreProvider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Layout>
            <After data={data} routes={routes} />
          </Layout>
        </ThemeProvider>
      </BrowserRouter>
    </StoreProvider>,
    document.getElementById("root")
  )
);

if (module.hot) {
  module.hot.accept();
}
