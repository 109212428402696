import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Modal from "./components/modal/modal";
import Nav from "./components/nav/Nav";
import { useStoreState, useStoreActions } from "easy-peasy";
import { store } from "./stores/store";

const Layout = ({ children, title = "ARK OF NOMMO" }) => {
  const [listening, setListening] = useState(false);

  useEffect(() => {
    (function (m, e, t, r, i, k, a) {
      m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
      m[i].l = 1 * new Date(); k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
    })
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    ym(72218107, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      ecommerce: "dataLayer"
    });

    if (!listening) {
      const socket = new WebSocket("wss://nommo.io");
      // const socket = new WebSocket("ws://localhost:3000/");
      // const socket = new WebSocket("wss://wonderful-sheep-19.loca.lt/");

      socket.onopen = function (e) {
        console.log("[open] Соединение установлено");
        socket.send("Меня зовут Джон");
      };

      socket.onmessage = function (event) {
        if (event.data.includes("type")) {
          const resp = JSON.parse(event.data);
          if (resp.type === "prognose") {
            store.getActions().addOnePrognoses(resp.data);
          } else if (resp.type === "dayModel") {
            store.getActions().addOnedaysModel(resp.data);
          } else if (resp.type === "prognose-removed") {
            store.dispatch.deletePrognoses(resp.data);
          } else if (resp.type === "dayModel-removed") {
            store.dispatch.deleteDaysModel(resp.data);
          } else if (resp.type === "day") {
            store.dispatch.addOneDay(resp.data);
          }
        }
      };

      socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(
            `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
          );
          setListening(false);
        } else {
          setListening(false);
          // например, сервер убил процесс или сеть недоступна
          // обычно в этом случае event.code 1006
          console.log("[close] Соединение прервано");
        }
      };

      socket.onerror = function (error) {
        console.log(error);
      };

      setListening(true);
    }
  }, [listening]);

  const setMobile = useStoreActions((actions) => actions.set_mobile);
  const urlStore = useStoreState((state) => state.url);
  useEffect(() => {
    if (window.innerWidth > 1250) {
      setMobile(false);
    } else {
      setMobile(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1250) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      {(urlStore.includes("admin") ||
        urlStore.includes("post") ||
        urlStore.includes("day")) && <Nav />}
      {children}
      <Modal />
      {/* <footer>{"I`m here to stay"}</footer> */}
    </>
  );
};

export default Layout;
