import { action, createStore, thunk } from "easy-peasy";
import authService from "../service/auth";

export function initiateStore() {
  return createStore({
    showPopup: false,
    set_showPopup: action((state, payload) => {
      state.showPopup = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    usersCount: 0,
    set_usersCount: action((state, payload) => {
      state.usersCount = payload;
    }),
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    groups: [],
    set_groups: action((state, payload) => {
      state.groups = payload;
    }),
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    top: {},
    set_top: action((state, payload) => {
      state.top = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    price: {},
    set_price: action((state, payload) => {
      state.price = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    ticks: [],
    set_ticks: action((state, payload) => {
      state.ticks = [...payload];
    }),
    pushTick: action((state, payload) => {
      if (state.ticks.length > 3) {
        state.ticks = [];
      }
      state.ticks.push(payload);
      state.ticks.sort(function (a, b) {
        return a.payload.value - b.payload.value;
      });
      console.log(state.ticks);
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    daysModel: [],
    set_daysModel: action((state, payload) => {
      state.daysModel = [...payload];
    }),
    addOnedaysModel: action((state, payload) => {
      const ind = state.daysModel.map((e) => e._id).indexOf(payload._id);
      if (ind > -1) {
        state.daysModel[ind] = { ...payload };
      } else {
        state.daysModel.push(payload);
      }
      state.daysModel.sort(function (a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    }),
    deleteDaysModel: action((state, payload) => {
      state.daysModel = state.daysModel.filter((el) => {
        return el._id != payload;
      });
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    prognosesOr: true,
    set_prognosesOr: action((state, payload) => {
      state.prognosesOr = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    newOne: {
      avatar: null,
      date: new Date(),
      intensity: "Weak",
      success: false,
      type: "Up",
      sure: false,
      published: true,
    },
    refreshNewOne: action((state) => {
      state.newOne = {
        avatar: null,
        date: new Date(),
        intensity: "Weak",
        success: false,
        type: "Up",
        sure: false,
        published: true,
      };
    }),
    set_newOne: action((state, payload) => {
      state.newOne = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    secondOne: {
      date: new Date(),
      parameter: "Up",
    },
    refreshSecondOne: action((state) => {
      state.secondOne = {
        date: new Date(),
        parameter: "Up",
      };
    }),
    set_secondOne: action((state, payload) => {
      state.secondOne = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    file: null,
    set_file: action((state, payload) => {
      state.file = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    days: [],
    set_days: action((state, payload) => {
      state.days = [...payload];
    }),
    addOneDay: action((state, payload) => {
      const ind = state.days.map((e) => e._id).indexOf(payload._id);
      if (ind > -1) {
        state.days[ind] = payload;
      } else {
        state.days.push(payload);
      }
      state.days.sort(function (a, b) {
        return b.time * 1000 - a.time * 1000;
      });
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    url: "",
    set_url: action((state, payload) => {
      state.url = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    user: null,
    set_user: action((state, payload) => {
      state.user = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    prognoses: [],
    set_prognoses: action((state, payload) => {
      state.prognoses = [...payload];
    }),
    addOnePrognoses: action((state, payload) => {
      const ind = state.prognoses.map((e) => e._id).indexOf(payload._id);
      if (ind > -1) {
        state.prognoses[ind] = { ...payload };
      } else {
        state.prognoses.push(payload);
      }
      state.prognoses.sort(function (a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    }),
    deletePrognoses: action((state, payload) => {
      state.prognoses = state.prognoses.filter((el) => {
        return el._id != payload;
      });
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    picture: null,
    set_picture: action((state, payload) => {
      state.picture = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    mobile: false,
    set_mobile: action((state, payload) => {
      state.mobile = payload;
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    set_initialState: action((state, initial) => {
      const arr = Object.keys(initial);
      arr.forEach((el) => {
        state[el] = initial[el];
      });
    }),
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    logOut: thunk(async (actions) => {
      const { success, message } = await authService.logOut();
      if (success) {
        actions.set_user(null);
        window.location.href = "/";
      } else {
        alert(message);
      }
    }),
  });
}

export const store = initiateStore();
