import "./modal.css";
import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Fade from "@material-ui/core/Fade";

const Modal = () => {
  const picture = useStoreState((state) => state.picture);
  const setPicture = useStoreActions((actions) => actions.set_picture);
  return (
    <Fade in={picture !== null} timeout={500}>
      <div
        className="overlay"
        onClick={() => {
          setPicture(null);
        }}
      >
        <img src={picture} className="picture" />
      </div>
    </Fade>
  );
};

export default Modal;
