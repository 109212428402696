import React, { useState } from "react";
import "./Popup.css";
import Fade from "@material-ui/core/Fade";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const Popup = ({ show, setShow }) => {
  const groups = useStoreState((state) => state.groups);
  const set_groups = useStoreActions((actions) => actions.set_groups);
  const [showForm, setshowForm] = useState(false);
  const [title, settitle] = useState("");
  const [ID, setID] = useState("");

  const create = async () => {
    if (!title || !ID) alert("Fields can not be empty");
    const response = await fetch("/api/groups", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ groupId: ID, title }),
    }).then((j) => j.json());
    if (!response.success) {
      alert(response.mess);
    } else {
      const arr = [...groups];
      arr.push(response.doc);
      set_groups(arr);
      settitle("");
      setID("");
      setshowForm(false);
    }
  };

  const deleteGroup = async (id) => {
    const response = await fetch("/api/groups/" + id, {
      method: "DELETE",
      credentials: "include",
    }).then((j) => j.json());
    if (!response.success) {
      alert(response.mess);
    } else {
      const arr = groups.filter((el) => el._id != id);
      set_groups(arr);
    }
  };

  return (
    <Fade in={show} timeout={500}>
      <div>
        <div className="overlay-popup" onClick={() => setShow(false)}></div>
        <div className="main-popup">
          <table className="table-popup" cellSpacing="20px">
            <thead>
              <tr>
                <th>Title</th>
                <th>ID</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((el, ind) => (
                <tr key={ind}>
                  <td align="center">{el.title}</td>
                  <td align="center">{el.groupId}</td>
                  <td
                    align="center"
                    onClick={() => deleteGroup(el._id)}
                    style={{ cursor: "pointer" }}
                  >
                    delete
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="formContainer-popup">
            {!showForm && (
              <Button color="inherit" onClick={() => setshowForm(true)}>
                Add group
              </Button>
            )}
            {showForm && (
              <div className="form-popup">
                <TextField
                  required
                  id="title"
                  label="Title"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
                <TextField
                  required
                  id="ID"
                  label="ID"
                  value={ID}
                  onChange={(e) => setID(e.target.value)}
                />
                <Button color="inherit" onClick={create}>
                  Create
                </Button>
                <Button
                  color="inherit"
                  onClick={() => {
                    settitle("");
                    setID("");
                    setshowForm(false);
                  }}
                >
                  Close
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Popup;
