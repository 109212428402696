const logOut = async () => {
  const request = await fetch("/logout").then((j) => j.json());
  return request;
};

const savePrognose = async (newOne, file) => {
  const data = new FormData();
  data.append("obj", JSON.stringify(newOne));
  if (file) {
    data.append("image", file);
  }
  const request = await fetch(`/api/newPrognose`, {
    method: "POST",
    credentials: "include",
    body: data,
  }).then((j) => j.json());
  return request;
};

const saveDay = async (newOne) => {
  const request = await fetch(`/api/newDay`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newOne),
  }).then((j) => j.json());
  return request;
};

const deletePrognose = async (id) => {
  const request = await fetch(`/api/deletePrediction/${id}`).then((j) =>
    j.json()
  );
  return request;
};

const deleteDay = async (id) => {
  const request = await fetch(`/api/deleteDay/${id}`).then((j) => j.json());
  return request;
};

class Auth {
  constructor() {
    this.logOut = logOut;
    this.savePrognose = savePrognose;
    this.deletePrognose = deletePrognose;
    this.saveDay = saveDay;
    this.deleteDay = deleteDay;
  }
}

const authService = new Auth();

export default authService;
