import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useStoreActions, useStoreState } from "easy-peasy";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SaveIcon from "@material-ui/icons/Save";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import authService from "../../service/auth";
import { useHistory } from "react-router-dom";
import Popup from "../Popup/Popup";
dayjs.extend(utc);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rightBtn: {
    position: "absolute",
    right: "0",
  },
  tabs: {
    transform: "scale(1)",
    transition: "all 0.4s",
  },
  activeLink: {
    transform: "scale(1.1)",
    color: "#9c9cf7",
  },
}));

const Nav = () => {
  const classes = useStyles();
  const history = useHistory();
  const logOutAction = useStoreActions((actions) => actions.logOut);
  const setFile = useStoreActions((actions) => actions.set_file);
  const setNewOne = useStoreActions((actions) => actions.set_newOne);
  const refreshNewOne = useStoreActions((actions) => actions.refreshNewOne);
  const mobile = useStoreState((state) => state.mobile);
  const usersCount = useStoreState((state) => state.usersCount);
  const url = useStoreState((state) => state.url);
  const newOne = useStoreState((state) => state.newOne);
  const secondOne = useStoreState((state) => state.secondOne);
  const file = useStoreState((state) => state.file);
  const prognoses = useStoreState((state) => state.prognoses);
  const setPrognosesOr = useStoreActions((actions) => actions.set_prognosesOr);
  const prognosesOr = useStoreState((state) => state.prognosesOr);
  const refreshSecondOne = useStoreActions(
    (actions) => actions.refreshSecondOne
  );
  const setSecondOne = useStoreActions((actions) => actions.set_secondOne);
  const [show, setshow] = useState(false);

  const saveNewOne = async (mode) => {
    if (!newOne._id) {
      const checkOne = prognoses.filter((el) => {
        return (
          dayjs.utc(el.date).utcOffset(3).format("DD.MM.YYYY") ===
          dayjs.utc(newOne.date).utcOffset(3).format("DD.MM.YYYY")
        );
      });
      if (checkOne.length > 0) {
        alert("Прогноз на эту дату уже есть");
        return;
      }
    }
    const result = await authService.savePrognose(
      {
        ...newOne,
        avatar: newOne.uploaded || newOne.oldAvatar ? null : newOne.avatar,
      },
      file
    );
    if (result.success) {
      if (mode) {
        setTimeout(() => {
          setFile(null);
          refreshNewOne();
        }, 500);
        history.push("/admin");
      } else {
        setNewOne(result.mess);
        setFile(null);
        alert("Saved!");
      }
    } else {
      alert(result.mess);
    }
  };

  const saveNewOneDay = async (mode) => {
    const result = await authService.saveDay({ ...secondOne });
    if (result.success) {
      if (mode) {
        setTimeout(() => {
          refreshSecondOne();
        }, 500);
        history.push("/admin");
      } else {
        setSecondOne(result.mess);
        alert("Saved!");
      }
    } else {
      alert(result.mess);
    }
  };

  const logout = () => {
    logOutAction();
  };

  const populate = async () => {
    if (
      window.confirm("Вы уверены, что хотите обновить базу данных по дням?")
    ) {
      try {
        const response = await fetch("/api/populateBTC");
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Popup show={show} setShow={setshow} />
      <AppBar position="static">
        <Toolbar>
          {url.includes("admin") ? (
            <>
              <Button
                color="inherit"
                className={`${classes.tabs} ${
                  prognosesOr && classes.activeLink
                }`}
                onClick={() => setPrognosesOr(true)}
              >
                Prognoses
              </Button>
              <Button
                color="inherit"
                className={`${classes.tabs} ${
                  !prognosesOr && classes.activeLink
                }`}
                onClick={() => setPrognosesOr(false)}
              >
                Days
              </Button>
              {!mobile && (
                <Button
                  color="inherit"
                  className={`${classes.tabs}`}
                  onClick={populate}
                >
                  Populate Days
                </Button>
              )}
              {!mobile && (
                <Button
                  color="inherit"
                  className={`${classes.tabs}`}
                  onClick={() => setshow(true)}
                >
                  Manage groups
                </Button>
              )}
              {!mobile && (
                <>
                  <Link to="/post/new">
                    <Button color="inherit">
                      <AddBoxIcon /> &nbsp; Prognose
                    </Button>
                  </Link>
                  <Link to="/day/new">
                    <Button color="inherit">
                      <AddBoxIcon /> &nbsp; Day
                    </Button>
                  </Link>
                </>
              )}
              {!mobile && (
                <h3 style={{ marginLeft: "10px" }}>
                  Total subscriptions: {usersCount}
                </h3>
              )}
            </>
          ) : (
            <>
              <Link to="/admin">
                <Button color="inherit">
                  {!mobile ? (
                    <>
                      <ArrowBackIosIcon /> &nbsp; Exit without saving
                    </>
                  ) : (
                    <ArrowBackIosIcon />
                  )}
                </Button>
              </Link>

              <Button
                color="inherit"
                onClick={() =>
                  url.includes("post") ? saveNewOne(true) : saveNewOneDay(true)
                }
              >
                {!mobile ? (
                  <>
                    <ArrowBackIosIcon />
                    <SaveIcon /> &nbsp; Save and exit
                  </>
                ) : (
                  <>
                    <ArrowBackIosIcon />
                    <SaveIcon />
                  </>
                )}
              </Button>
              <Button
                color="inherit"
                onClick={() =>
                  url.includes("post")
                    ? saveNewOne(false)
                    : saveNewOneDay(false)
                }
              >
                {!mobile ? (
                  <>
                    <SaveIcon /> &nbsp; Save
                  </>
                ) : (
                  <>
                    <SaveIcon />
                  </>
                )}
              </Button>
            </>
          )}
          <Button color="inherit" className={classes.rightBtn} onClick={logout}>
            <ExitToAppIcon />
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Nav;
